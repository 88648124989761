import request from "@/utils/request";

const ip = "https://zsy.ai-me.cn";

// 进入页面
// export const burialPoint = (data, params) =>
//   request({
//     url: "/op-service/v1/jiayou20240329/join/",
//     method: "post",
//     ipBaseURL: ip,
//     data,
//     params,
//   });

// 发送短信
export const sendSMS = (data, params) =>
  request({
    url: "/op-service/v1/zunxiang20240613/send-sms/",
    method: "post",
    ipBaseURL: ip,
    data,
    params,
  });

// 订购业务
export const buy = (data, params) =>
  request({
    url: "/op-service/v1/zunxiang20240613/buy/",
    method: "post",
    ipBaseURL: ip,
    data,
    params,
  });
